export const MENU_ITEMS_BY_POST = {
  'resolve-request': [
    {
      name: 'Порядок работы с заявками',
      link: 'request-flow',
    },
    {
      name: 'Роли исполнителей',
      link: 'employee-roles',
    },
    {
      name: 'Обзор кабинета исполнителя',
      link: 'employee-profile',
    },
    {
      name: 'Вкладка «Мои»',
      link: 'my-tab',
    },
    {
      name: 'Что можно сделать с заявкой?',
      link: 'staff-tab',
    },
    {
      name: 'Вкладка «Подчинённые»',
      link: 'subordinates-tab',
    },
    {
      name: 'Вкладка «На проверку»',
      link: 'for-review-tab',
    },
    {
      name: 'Архив заявок',
      link: 'archive',
    },
    {
      name: 'Статусы заявки',
      link: 'request-status',
    },
  ],
  'company-history': [
    {
      name: 'Началось с поддержки продукта',
      link: 'started-as-support',
    },
    {
      name: 'Трансформация в развитие собственного продукта',
      link: 'transformation-to-growth',
    },
    {
      name: 'Полная переработка архитектуры',
      link: 'complete-remaking',
    },
    {
      name: 'Что дальше?',
      link: 'whats-next',
    },
  ],
  'create-request': [
    {
      name: 'Порядок работы с заявками',
      link: 'request-flow',
    },
    {
      name: 'Способ 1: создайте заявку без установки приложения',
      link: 'create-without-app-installation',
    },
    {
      name: 'Способ 2: создайте заявку через приложение',
      link: 'create-using-app',
    },
    {
      name: 'Преимущества установки Wave Service',
      link: 'benefits-of-installed-app',
    },
    {
      name: 'Как отслеживать статус заявки?',
      link: 'monitor-status',
    },
    {
      name: 'Как оценить качество выполнения заявки?',
      link: 'rate-works',
    },
  ],
  'qr-location': [
    {
      name: 'Помещения и их типы',
      link: 'locations-and-types',
    },
    {
      name: 'Как создать и настроить тип помещения',
      link: 'create-location-type',
    },
    {
      name: 'Как создать помещение',
      link: 'create-location',
    },
    {
      name: 'Как создать иерархию локаций',
      link: 'create-location-hierarchy',
    },
    {
      name: 'Как привязать QR-код к помещению',
      link: 'assign-qr-code',
    },
  ],
  'case-ecoclub': [
    {
      name: 'Новый клиент',
      link: 'new-client',
    },
    {
      name: 'Задачи автоматизации бонусной системы',
      link: 'problem',
    },
    {
      name: 'Причём тут мы',
      link: 'why-us',
    },
    {
      name: 'Что было сделано',
      link: 'solution',
    },
    {
      name: 'Результат',
      link: 'result',
    },
    {
      name: 'Чему научился Wave Service',
      link: 'what-we-achieved',
    },
  ],
  'choose-service-desk-for-offices': [
    {
      name: 'Для тех, кому интересна история',
      link: 'history-user-requests',
    },
    {
      name: 'Facility Management',
      link: 'facility-management',
    },
    {
      name: 'CRM',
      link: 'crm',
    },
    {
      name: 'FSM',
      link: 'fsm',
    },
    {
      name: 'Help desk',
      link: 'help-desk',
    },
    {
      name: 'Service desk',
      link: 'service-desk',
    },
    {
      name: 'Как не запутаться и выбрать подходящую систему',
      link: 'choose-software',
    },
  ],
  'whatsapp-vs-service-desk': [
    {
      name: 'Автоматизация по старинке',
      link: 'automation-old-ways',
    },
    {
      name: 'Когда WhatsApp* и Excel уже не помогают',
      link: 'when-messengers-cant-help',
    },
    {
      name: 'Как Wave Service помогает автоматизировать внутренние задачи',
      link: 'how-wave-service-helps',
    },
    {
      name: 'Развеиваем ваши сомнения',
      link: 'common-doubts',
    },
  ],
  'case-coworking-automation': [
    {
      name: 'Рабочее пространство 21 века',
      link: 'modern-office',
    },
    {
      name: 'Кто создаёт атмосферу и удобство',
      link: 'who-creates-comfortable-spaces',
    },
    {
      name: 'Как Wave Service помогает сервисному офису CASE',
      link: 'how-wave-service-helps',
    },
    {
      name: 'Что ещё возможно автоматизировать в гибридном офисе',
      link: 'hybrid-office-automation',
    },
  ],
  'case-entry-permits-teorema': [
    {
      name: 'Клиент',
      link: 'client',
    },
    {
      name: 'Контекст',
      link: 'context',
    },
    {
      name: 'Временные пропуска: проблемы и поиск решения',
      link: 'problem',
    },
    {
      name: 'Как мы помогли',
      link: 'solution',
    },
    {
      name: 'Что получилось',
      link: 'result',
    },
  ],
  'suggested-replies': [
    {
      name: 'Ситуация',
      link: 'problem',
    },
    {
      name: 'Решение',
      link: 'solution',
    },
  ],
  'case-developer-triangle': [
    {
      name: 'О кейсе: участники, цели и задачи',
      link: 'about',
    },
    {
      name: 'Подготовка к внедрению и проблемы',
      link: 'deployment-and-problems',
    },
    {
      name: 'Выводы команды Wave Service',
      link: 'takeaways',
    },
    {
      name: 'Вместо заключения: проблемы цифровизации на командном опыте',
      link: 'conclusion',
    },
  ],
  'case-hotel-automation': [
    {
      name: 'Клиент',
      link: 'client',
    },
    {
      name: 'Цели',
      link: 'business-goals',
    },
    {
      name: 'Как помогает Wave Service',
      link: 'solution',
    },
    {
      name: 'Результаты',
      link: 'results',
    },
  ],
  'case-shared-parking-app': [
    {
      name: 'Ситуация',
      link: 'situation',
    },
    {
      name: 'Проблемы',
      link: 'problems',
    },
    {
      name: 'Решение',
      link: 'solution',
    },
  ],
};
