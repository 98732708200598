import {
  PostIntro, RecommendedPosts, useSitePosts,
} from '@entities/post';
import { Layout } from '@widgets/layout';
import { NotFoundPage } from '@routes/not-found';
// ui
import MediaQuery from '@ui/MediaQuery';
import MarkdownViewer from '@ui/MarkdownViewer'; 
import { PostMenuSideBar } from '@ui/SideBar';
// styles
import { block } from 'bem-cn';
import { graphql } from 'gatsby';
import {
  memo, useEffect, useRef,
} from 'react';

import { usePostVisits } from '@hooks/usePostVisit';
import { useLocation } from '@reach/router';

import './style.scss';
import './postContentStyle.scss';

const cn = block('post-page');

// ----------------------------------------------------------------------------

const Template = ({ data }) => {
  const { hash } = useLocation();
  const postView = usePostVisits();
  let { markdownRemark } = data;
  if (markdownRemark === null || markdownRemark === undefined) {
    markdownRemark = { frontmatter: {}, htmlAst: {}, timeToRead: 0 };
  }
  const { frontmatter, htmlAst, timeToRead } = markdownRemark;
  const duration = timeToRead;
  const {
    slug,
    coverImageUrl,
    SeoDescription,
    SeoTitle,
    sidemenu,
  } = frontmatter;

  const postContentRef = useRef();

  useEffect(() => {
    if (hash && postContentRef.current) {
      const tag = postContentRef.current.querySelector(hash);
      if (tag) {
        tag.scrollIntoView();
      }
    }
  }); // don`t use dependencies array for useEffect. tag is null by reload if use array

  const recommendedPosts = useSitePosts().recommended;

  if (slug === undefined) {
    return NotFoundPage;
  }
  return (
    <Layout
      seo={{
        title: SeoTitle,
        description: SeoDescription,
        link: slug,
        type: 'article',
        image: coverImageUrl,
      }}
      banner
      bodyClassName="bg-tertiary100"
    >
      <Layout.Intro>
        <div className="grid-layout mx-auto">
          <PostIntro post={{ ...frontmatter, duration }} />
        </div>
      </Layout.Intro>
      <MediaQuery lessThan="lg">
        <div className="gradient_light relative -mt-2 sm:mx-1 md:mx-2 lg:mx-3">
          <div className="grid-layout mx-auto mb-5 pt-7 pb-5">
            <PostMenuSideBar postContentRef={postContentRef} data={sidemenu || null} />
          </div>
        </div>
      </MediaQuery>
      <article className="grid-layout mx-auto sm:px-3 lg:flex">
        <div className="lg:col-offset-start-1 lg:col-8">
          <div className={cn('post', { container: true })}>
            <div
              className={cn('post', { content: true })
                .mix(
                  cn('content', { wrap: true }),
                  'lg:col-8',
                )}
              ref={postContentRef}
            >
              <MarkdownViewer>
                {htmlAst}
              </MarkdownViewer>
            </div>
            <MediaQuery greaterThan="md">
              <PostMenuSideBar postContentRef={postContentRef} data={sidemenu || null} />
            </MediaQuery>
          </div>
        </div>
      </article>
      <RecommendedPosts
        className="pt-12 lg-down:pt-7"
        posts={recommendedPosts}
        listClassName="lg:mt-6 lg:mb-[50px] lg-down:mt-5 lg-down:mb-6"
      />
    </Layout>
  );
};

export default memo(Template);

export const query = graphql`
query($id: String!) {
  markdownRemark(
    id: {eq: $id},
    frontmatter: { type: {eq: "post"}}
    ) {
    htmlAst
    timeToRead
      frontmatter {
          author
          category
          coverImageUrl
          coverImageAlt
          createdAt
          slug
          title
          avatar
          sourceHref
          sidemenu {
            name
            link
          }
          SeoTitle
          SeoDescription
          downloadButton {
            text
            modal
            docname  
          }
      }
    }
  }
`;
